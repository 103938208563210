var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table-page", {
    directives: [
      {
        name: "show",
        rawName: "v-show",
        value: _vm.isLoaded,
        expression: "isLoaded",
      },
    ],
    staticClass: "EmailsTemplatesTable",
    attrs: {
      tableName: _vm.$options.name,
      columns: _vm.columns,
      filterModel: _vm.filterModel,
      headerTitle: "emails",
      tooltipMsg: "manualforemails",
      requestFunction: _vm.requestFunction,
      defaultOrderBy: { column: "PemailId", ascending: 0 },
      modelId: "PemailId",
    },
    on: {
      addNewRecord: function ($event) {
        return _vm.redirectToDetail()
      },
      editRecord: function ($event) {
        return _vm.redirectToDetail($event)
      },
      filterChanged: function ($event) {
        return _vm.handleFilter($event)
      },
      removeFilters: function ($event) {
        return _vm.removeAllFilters($event)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "PemailId",
        fn: function (list) {
          return [_vm._v("E" + _vm._s(list.row.PemailId))]
        },
      },
      {
        key: "PemailType",
        fn: function (list) {
          return [_vm._v(_vm._s(_vm.pemailTypeTexts[list.row.PemailType]))]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }