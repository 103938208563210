export const tableColumns = [
	{
		model: 'PemailId',
		i18n: 'eid',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'PemailName',
		i18n: 'emailname',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'PemailSubjectSk',
		i18n: 'subject',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'Conversions',
		i18n: 'conversion',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'PemailType',
		i18n: 'type2877',
		sortable: true,
		filter: 'dropdown',
	},
	{
		colType: 'boolean',
		model: 'PemailIsValid',
		i18n: 'checked',
		sortable: true,
	},
];
