<template>
	<table-page
		v-show="isLoaded"
		:tableName="$options.name"
		:columns="columns"
		:filterModel="filterModel"
		headerTitle="emails"
		tooltipMsg="manualforemails"
		:requestFunction="requestFunction"
		:defaultOrderBy="{ column: 'PemailId', ascending: 0 }"
		modelId="PemailId"
		@addNewRecord="redirectToDetail()"
		@editRecord="redirectToDetail($event)"
		@filterChanged="handleFilter($event)"
		@removeFilters="removeAllFilters($event)"
		class="EmailsTemplatesTable"
	>
		<template #PemailId="list">E{{ list.row.PemailId }}</template>

		<template #PemailType="list">{{ pemailTypeTexts[list.row.PemailType] }}</template>
	</table-page>
</template>

<script>
import TablePage from '@/components/general/table-page';
import { tablePageMixin } from '@/components/general/table-page.mixin';
import * as links from '@/router/links';
import serviceEnums from '@/services/service/enums.service';
import { getColumnDropdownOptionsAsDict, setDropdownOptions } from '@/components/general/utils';

import { tableColumns } from './emails-templates.table-data';

export default {
	name: 'PortalEmailTable',

	components: {
		TablePage,
	},

	mixins: [tablePageMixin],

	data() {
		return {
			controllerName: 'PortalEmailListItem',
			columns: tableColumns,
		};
	},

	computed: {
		pemailTypeTexts() {
			return getColumnDropdownOptionsAsDict(this.columns, 'PemailType');
		},
	},

	async created() {
		this.columns = await this.prepareColumns(tableColumns);
	},

	methods: {
		redirectToDetail(PemailId = 0) {
			this.$router.push({
				name: links.emailsTemplateDetailName,
				params: { PemailId },
			});
		},

		async prepareColumns(columns) {
			let cols = preparePemailType(columns);

			return cols;

			function preparePemailType(columns) {
				const values = serviceEnums.getEnumForDropdown('pemailTypeFilter', true);

				return setDropdownOptions(columns, 'PemailType', values);
			}
		},
	},
};
</script>
<style lang="scss">
.EmailsTemplatesTable {
	.VueTables__filters-row > th:nth-child(1),
	th:nth-child(2) {
		min-width: 55px;
		width: 55px;
	}
}
</style>